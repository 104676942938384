import React from 'react';
import { FaPlayCircle as PlayIcon, FaStar as StarIcon } from 'react-icons/fa';
import { addToFavouriteGame, removeFavouriteGame } from '../../redux/actions';
import { useAlert } from '../AlertContainer/AlertContainer';
import { useDispatch } from 'react-redux';
import useGames from '../../hooks/useGames';
import classNames from 'classnames';
import useResize from '../../hooks/useResize';

const MarkFavGame = ({ id, isList }) => {
    const { showAlert } = useAlert();
    const dispatch = useDispatch();
    const { favorites } = useGames();
    const active = favorites.indexOf(id) >= 0;

    const toggleFavourite = () => {
        if (active) {
            dispatch(removeFavouriteGame(id));
            showAlert({ status: 'failure', msg: 'success_remove_favorites' });
        } else {
            dispatch(addToFavouriteGame(id));
            showAlert({ status: 'success', msg: 'success_add_favorites' });
        }
    };
    return (
        <div
            className={classNames('game-list-item-fav', {
                'fav-active': active,
                'list-style': isList,
            })}
            onClick={toggleFavourite}
        >
            <StarIcon />
        </div>
    );
};

export default MarkFavGame;
