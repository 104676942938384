import React from 'react';
import Header from '../../components/Header/Header';
import { useNavigate, useParams } from 'react-router-dom';
import useGames from '../../hooks/useGames';
import GameCard from '../../components/Games/GameCard';
import { Row, Col } from 'react-simple-flex-grid';
import useTranslate from '../../hooks/useTranslate';
import useSearch from '../../hooks/useSearch';
import DisplayGames from '../../components/Games/DisplayGames';
import useGetSearchContents from '../../hooks/useGetSearchContents';

const CategoryGames = () => {
    const navigate = useNavigate();
    const { getCategoryAllGames } = useGames();
    const { id } = useParams();
    const categoryGames = getCategoryAllGames(id);
    const t = useTranslate();
    const { searchTerm, ...searchProps } = useSearch();
    const searchGames = useGetSearchContents(searchTerm, categoryGames, '', 'games');
    const categoryName = categoryGames && categoryGames.length > 0 && categoryGames[0].categoryName;
    return (
        <>
            <Header searchTerm={searchTerm} {...searchProps} showBackButton onBackButton={() => navigate('/home/game')} forceHideHamburger />
            <div className="game-category-list">
                {categoryName && <div className="category-title">{t(categoryName, {}, categoryName)}</div>}
                {searchTerm && searchTerm.length > 0 ? (
                    <DisplayGames games={searchGames} />
                ) : categoryGames && categoryGames.length > 0 ? (
                    <DisplayGames games={categoryGames} />
                ) : null}
            </div>
        </>
    );
};

export default CategoryGames;
