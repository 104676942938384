import React from 'react';
import Header from '../../components/Header/Header';
import useTranslate from '../../hooks/useTranslate';
import classNames from 'classnames';
import { _isAMBFooterSubdomain } from '../../constants/conditionals';
import useGetPageConfig from '../../hooks/useGetPageConfig';
import getBanners from '../../utils/getBanners';
import BannerSlider from '../../components/Slider/BannerSlider';
import useGames from '../../hooks/useGames';
import { useNavigate } from 'react-router-dom';
import Slider from '../../components/Slider/Slider';
import GameCard from '../../components/Games/GameCard';
import useSearch from '../../hooks/useSearch';
import useGetSearchContents from '../../hooks/useGetSearchContents';
import { useDispatch } from 'react-redux';
import { addToGameHistory } from '../../redux/actions';

const Games = () => {
    const t = useTranslate();
    const { rtl } = useGetPageConfig();
    const { games, getGamesCategory } = useGames();
    const categories = getGamesCategory();
    const { searchTerm, ...searchProps } = useSearch();
    const searchGames = useGetSearchContents(searchTerm, games, 'exact', 'games');
    return (
        <>
            <Header searchTerm={searchTerm} {...searchProps} />
            <div className={classNames('home-page', { 'page-layout-amb-footer': _isAMBFooterSubdomain })} dir={rtl ? 'rtl' : 'ltr'}>
                <div className="home-page-banners">
                    <BannerSlider slides={getBanners()} />
                </div>
                <div className="home-page-controls">
                    <div className="title">{t('all_categories')}</div>
                </div>
                <div className="home-page-contents">
                    {searchTerm && searchTerm.length > 0 ? (
                        <GamesList games={searchGames} categories={categories} />
                    ) : (
                        <GamesList games={games} categories={categories} />
                    )}
                </div>
            </div>
        </>
    );
};

const GamesList = ({ games, categories }) => {
    const t = useTranslate();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    return (
        <>
            {categories.map((c) => {
                return (
                    <div key={c.categoryId} className="category-wrapper">
                        <div className="category-head">
                            <div className="title">{t(c.categoryName, {}, c.categoryName)}</div>
                            <button onClick={() => navigate('/category/game/' + c.categoryId)}>
                                <span>{t('view_all')}</span>
                            </button>
                        </div>
                        <div className="category-games">
                            <Slider
                                slides={games
                                    .filter((x) => x.categoryId === c.categoryId)
                                    .map((item) => (
                                        <GameCard
                                            key={item.gameId}
                                            id={item.gameId}
                                            type={item.itemType}
                                            title={item.gameTitle}
                                            imageURL={item.thumbnail}
                                            onClick={() => {
                                                dispatch(addToGameHistory(item.gameId));
                                                navigate('/play/game/' + item.gameId);
                                            }}
                                        />
                                    ))}
                            />
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default Games;
