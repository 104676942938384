import classNames from 'classnames';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'react-simple-flex-grid';
import Header from '../../components/Header/Header';
import VideoCard from '../../components/VideoCard/VideoCard';
import VideoList from '../../components/VideoList/VideoList';
import useGetSearchContents from '../../hooks/useGetSearchContents';
import useResize from '../../hooks/useResize';
import useSearch from '../../hooks/useSearch';
import useTranslate from '../../hooks/useTranslate';
import useGetMovies from '../../hooks/useMovies';
import { _isAMBFooterSubdomain } from '../../constants/conditionals';

export default function CategoryMovies() {
    const { id: categoryId } = useParams();
    const navigate = useNavigate();
    const { getCategoryVideos } = useGetMovies();

    const categoryVideos = getCategoryVideos(categoryId);
    const categoryTitle = categoryVideos && categoryVideos.length > 0 ? categoryVideos[0].categoryName : '';

    const { searchTerm, ...searchProps } = useSearch();
    const searchVideos = useGetSearchContents(searchTerm, categoryVideos);
    const t = useTranslate();
    console.log(categoryVideos);
    return (
        <>
            <Header searchTerm={searchTerm} {...searchProps} forceHideHamburger showBackButton onBackButton={() => navigate('/home/movie')} />
            <div className={classNames('category-page', { 'page-layout-amb-footer': _isAMBFooterSubdomain })}>
                {categoryTitle && <div className="category-title">{t(categoryTitle)}</div>}
                <div className="category-videos">
                    {searchTerm && searchTerm.length > 0 ? (
                        <DisplayVideos videos={searchVideos} />
                    ) : categoryVideos && categoryVideos.length > 0 ? (
                        <DisplayVideos videos={categoryVideos} />
                    ) : null}
                </div>
            </div>
        </>
    );
}

const DisplayVideos = ({ videos }) => {
    const { width } = useResize();
    const navigate = useNavigate();
    return (
        <>
            {width > 576 ? (
                <Row
                    gutter={40}
                    style={{
                        marginTop: '1rem',
                    }}
                >
                    {videos.map((item) => (
                        <Col key={item.videoId} xs={6} sm={4} md={4} lg={3} xl={3}>
                            <VideoCard
                                id={item.videoId}
                                type={item.itemType}
                                title={item.videoTitle}
                                imageURL={item.videoPreview}
                                hideFavourite={true}
                                onClick={() => navigate('/play/movie/' + item.videoId)}
                                className="category-video-card"
                            />
                        </Col>
                    ))}
                </Row>
            ) : (
                <div className="category-videos-list">
                    {videos.map((item) => (
                        <VideoList
                            key={item.videoId}
                            id={item.videoId}
                            type={item.itemType}
                            imageURL={item.videoPreview}
                            title={item.videoTitle}
                            onClick={() => navigate('/play/movie/' + item.videoId)}
                        />
                    ))}
                </div>
            )}
        </>
    );
};
