import { actionTypes } from '../actions';

const initState = {
    games: [],
    gameFavorites: [],
    gamesHistory: [],
};

const GameReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.SET_GAMES:
            return { ...state, games: action.payload };
        case actionTypes.SET_FAVOURITE_GAMES:
            return {
                ...state,
                gameFavorites: action.payload.gamesIdsList,
            };
        case actionTypes.Add_TO_FAVOURITE_GAME:
            return { ...state, gameFavorites: [...state.gameFavorites, action.payload] };
        case actionTypes.REMOVE_FAVOURITE_GAME:
            return { ...state, gameFavorites: state.gameFavorites.filter((i) => i !== action.payload) };
        case actionTypes.SET_GAMES_HISTORY:
            return { ...state, gamesHistory: action.payload.gameHistoryList };
        case actionTypes.ADD_TO_GAME_HISTORY: {
            const t = state.gamesHistory.filter((i) => i.gameId !== action.payload.gameId);
            return { ...state, gamesHistory: [...t, action.payload] };
        }
        case actionTypes.CLEAR_MOVIE_HISTORY: {
            return { ...state, gamesHistory: [] };
        }
        default:
            return state;
    }
};

export default GameReducer;
