import React from 'react';
import MarkFavGame from './MarkFavGame';

const GameCard = ({ id, type, imageURL, title, onClick, style, className }) => {
    return (
        <div className="game-card-page">
            <div className="game-card-img" onClick={onClick}>
                <img src={imageURL} />
                <p className="game-title">{title}</p>
            </div>
            <MarkFavGame id={id} />
        </div>
    );
};

export default GameCard;
