import React from 'react';
import Header from '../../components/Header/Header';
import { useNavigate, useParams } from 'react-router-dom';
import useGames from '../../hooks/useGames';
import { FaTag as TagIcon, FaStar as StarIcon } from 'react-icons/fa';
import Slider from '../../components/Slider/Slider';
import useResize from '../../hooks/useResize';
import useTranslate from '../../hooks/useTranslate';
import GameCard from '../../components/Games/GameCard';
import { _isAMBFooterSubdomain } from '../../constants/conditionals';
import classNames from 'classnames';
import useGetPageConfig from '../../hooks/useGetPageConfig';
import { useDispatch } from 'react-redux';
import { addToFavouriteGame, removeFavouriteGame } from '../../redux/actions';
import { useAlert } from '../../components/AlertContainer/AlertContainer';
import ResGameList from '../../components/Games/ResGameList';

const PlayGame = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { getCurrGame, getRelatedVideos, favorites: favouriteIdsGames } = useGames();
    const currentGame = getCurrGame(id);
    const relatedGames = getRelatedVideos(id);
    const { rtl } = useGetPageConfig();
    const active = favouriteIdsGames.indexOf(currentGame.gameId) >= 0;
    const { showAlert } = useAlert();
    const dispatch = useDispatch();

    const toggleFavourite = () => {
        if (active) {
            dispatch(removeFavouriteGame(currentGame.gameId));
            showAlert({ status: 'failure', msg: 'success_remove_favorites' });
        } else {
            dispatch(addToFavouriteGame(currentGame.gameId));
            showAlert({ status: 'success', msg: 'success_add_favorites' });
        }
    };

    return (
        <>
            <Header showBackButton onBackButton={() => navigate('/home/game')} forceHideHamburger />

            <div
                className={classNames('game-play-page', { 'page-layout-amb-footer': _isAMBFooterSubdomain })}
                key={currentGame.gameId}
                dir={rtl ? 'rtl' : 'ltr'}
            >
                <div className="game-player">
                    <object data={currentGame.gameURL} className="open_game"></object>
                </div>
                <div className="game-desc">
                    <div className="game-category-desc">
                        <p className="game-title">{currentGame.gameTitle}</p>
                        <div className="game-category">
                            <TagIcon />
                            <p>{currentGame.categoryName}</p>
                        </div>
                    </div>
                    <div
                        className={classNames('game-fav', {
                            'fav-active': active,
                        })}
                        onClick={toggleFavourite}
                    >
                        <StarIcon />
                    </div>
                </div>
                {relatedGames && relatedGames.length > 0 ? (
                    <RelatedGamesList
                        relatedGames={relatedGames}
                        onClick={(gameId) => {
                            navigate('/play/game/' + gameId);
                        }}
                    />
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default PlayGame;

const RelatedGamesList = ({ relatedGames, onClick }) => {
    const { width } = useResize();
    const t = useTranslate();
    const navigate = useNavigate();
    return (
        <>
            <div className="related-games">
                <div className="related-games-header">
                    <div className="related-games-title">{t('related_videos')}</div>
                </div>
                <div className="related-games-contents">
                    {width > 756 ? (
                        <Slider
                            slides={relatedGames.map((item) => {
                                return (
                                    <GameCard
                                        key={item.gameId}
                                        type={item.itemType}
                                        id={item.gameId}
                                        title={item.gameTitle}
                                        imageURL={item.thumbnail}
                                        onClick={() => navigate('/play/game/' + item.gameId)}
                                    />
                                );
                            })}
                        />
                    ) : (
                        relatedGames.map((item) => (
                            <ResGameList
                                key={item.gameId}
                                id={item.gameId}
                                imageURL={item.thumbnail}
                                title={item.gameTitle}
                                onClick={() => navigate('/play/game/' + item.gameId)}
                            />
                        ))
                    )}
                </div>
            </div>
        </>
    );
};
