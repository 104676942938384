import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import useSetLanguage from '../hooks/useSetLanguage';
import useGetPageConfig from '../hooks/useGetPageConfig';
import * as actions from '../redux/actions';
import multiLangDomains from '../constants/multiLang';
import CookieBanner from '../components/CookieBanner/CookieBanner';
import { LanguageModal } from '../components/Modal/Modal';
import PrivateOutlet from '../PrivateOutlet';
import Home from './Home/Home';
import { VideoHomePage, VideoCategoryPage, VideoPlayPage } from './Videos';
import { MovieCategoryPage, MovieHomePage, MoviePlayPage } from './Movies';
import Account from './Account/Account';
import Favorites from './Favorites/Favorites';
import History from './History/History';
import Search from './Search/Search';
import Login from './Login';
import FAQ from '../pages/StaticPages/FAQ';
import HelpCentre from './HelpCentre/HelpCentre';
import TermsAndConditions from './StaticPages/TermsAndConditions';
import PrivacyPolicy from './StaticPages/PrivacyPolicy';
import { sendOnClickE, sendOnPageViewE } from '../analytics/data-collection';
import RefundPolicy from './StaticPages/RefundPolicy';
import ForgotPassword from './Login/ForgotPassword';
import Games from './Games/Games';
import PlayGame from './Games/PlayGame';
import GameCategoryPage from './Games/CategoryGames';

export default function Pages() {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { showLanguageModal, rtl, subdomain } = useGetPageConfig();
    const { activeLang, handleLanguageChange } = useSetLanguage();

    React.useEffect(() => {
        sendOnPageViewE(activeLang);
    }, [location, activeLang]);
    React.useEffect(() => window.scrollTo(0, 0), [location]);
    React.useEffect(() => {
        const redirectToHelpPage = () => navigate('/help-centre');
        if (window.location.hostname === 'help.moviotv.online') redirectToHelpPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <CookieBanner />
            {showLanguageModal && (
                <LanguageModal
                    rtl={rtl}
                    activeLang={activeLang}
                    multiLangData={multiLangDomains[subdomain]}
                    onClose={() => dispatch(actions.showLangModal(false))}
                    onLanguageChange={(item) => {
                        sendOnClickE({ name: `lang-change: ${activeLang} to ${item.value}` });
                        handleLanguageChange(item.value);
                    }}
                />
            )}
            <Routes>
                <Route path="" element={<PrivateOutlet />}>
                    <Route path="" element={<Navigate to="/home" />} />
                    <Route path="home" element={<Home />} />
                    <Route path="home/movie" element={<MovieHomePage />} />
                    <Route path="home/video" element={<VideoHomePage />} />
                    <Route path="category/video/:id" element={<VideoCategoryPage />} />
                    <Route path="category/movie/:id" element={<MovieCategoryPage />} />
                    <Route path="category/game/:id" element={<GameCategoryPage />} />
                    <Route path="play/video/:id" element={<VideoPlayPage />} />
                    <Route path="play/movie/:id" element={<MoviePlayPage />} />
                    <Route path="play/game/:id" element={<PlayGame />} />
                    <Route path="search" element={<Search />} />
                    <Route path="account" element={<Account />} />
                    <Route path="favorites" element={<Favorites />} />
                    <Route path="history" element={<History />} />
                    <Route path="/unsubscribe" element={<HelpCentre />} />
                    <Route path="home/game" element={<Games />} />
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/refund-policy" element={<RefundPolicy />} />
                <Route path="/help-centre" element={<HelpCentre />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </>
    );
}
