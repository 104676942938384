import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-simple-flex-grid';
import Header from '../../components/Header/Header';
import VideoCard from '../../components/VideoCard/VideoCard';
import VideoList from '../../components/VideoList/VideoList';
import useGetSearchContents from '../../hooks/useGetSearchContents';
import useGetVideos from '../../hooks/useGetVideos';
import useResize from '../../hooks/useResize';
import useSearch from '../../hooks/useSearch';

export default function Search() {
    const navigate = useNavigate();
    const { getAllVideosTransformed } = useGetVideos();
    const { searchTerm, ...searchProps } = useSearch(true);
    const searchVideos = useGetSearchContents(searchTerm, getAllVideosTransformed(), 'fuzzy');
    const { width } = useResize();

    return (
        <>
            <Header showActionButton={false} forceShowSearchOnMobile={true} searchTerm={searchTerm} {...searchProps} />
            <div className="category-page">
                <div className="category-videos">
                    {searchVideos && searchVideos.length > 0 ? (
                        width > 576 ? (
                            <Row
                                gutter={40}
                                style={{
                                    marginTop: '1rem',
                                }}
                            >
                                {searchVideos.map((item) => (
                                    <Col key={item.videoId} xs={6} sm={4} md={4} lg={3} xl={3}>
                                        <VideoCard
                                            id={item.videoId}
                                            type={item.itemType}
                                            title={item.videoTitle}
                                            imageURL={item.videoPreview}
                                            onClick={() => navigate('/play/video/' + item.videoId)}
                                            className="category-video-card"
                                        />
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <div className="category-videos-list">
                                {searchVideos.map((item) => (
                                    <VideoList
                                        key={item.videoId}
                                        id={item.videoId}
                                        imageURL={item.videoPreview}
                                        title={item.videoTitle}
                                        onClick={() => navigate('/play/video/' + item.videoId)}
                                    />
                                ))}
                            </div>
                        )
                    ) : null}
                </div>
            </div>
        </>
    );
}
