import { configureStore } from '@reduxjs/toolkit';
import { config } from '../constants/domainConfig';
import AccessReducer from './reducers/AccessReducer';
import ErrorReducer from './reducers/ErrorReducer';
import MovieReducer from './reducers/MovieReducer';
import PageReducer from './reducers/PageReducer';
import UserReducer from './reducers/UserReducer';
import VideoReducer from './reducers/VideoReducer';
import GameReducer from './reducers/GameReducer';

const store = configureStore({
    reducer: {
        access: AccessReducer,
        user: UserReducer,
        page: PageReducer,
        video: VideoReducer,
        error: ErrorReducer,
        movie: MovieReducer,
        game: GameReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: true }),
    devTools: true,
    preloadedState: {
        access: {},
        user: {},
        page: {
            country: config.country,
            lang: config.lang,
            showLanguageModal: false,
        },
        video: {
            videoFavorites: [],
            videoHistory: [],
            videos: [],
        },
        error: [],
        movie: {
            movies: [],
            moviesFavorites: [],
            moviesHistory: [],
        },
        game: {
            games: [],
            gameFavorites: [],
            gamesHistory: [],
        },
    },
});

export default store;
