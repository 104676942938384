import React, { useState } from 'react';
import Fuse from 'fuse.js';

export default function useGetSearchContents(searchTerm, content, type, itemType) {
    const [searchContent, setSearchContent] = useState([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fuse = new Fuse(content, {
        keys: itemType === 'games' ? ['gameTitle'] : ['videoTitle'],
    });
    React.useEffect(() => {
        setTimeout(() => {
            if (searchTerm && searchTerm.length > 0) {
                let t = [];
                if (type === 'exact') {
                    if (itemType === 'games') {
                        t = content.filter((i) => i.gameTitle.toLowerCase().includes(searchTerm.toLowerCase()));
                    } else {
                        t = content.filter((i) => i.videoTitle.toLowerCase().includes(searchTerm.toLowerCase()));
                    }
                } else t = fuse.search(searchTerm).map((i) => i.item);
                setSearchContent(() => t);
            } else setSearchContent(() => []);
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    return searchContent;
}
