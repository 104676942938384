import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-simple-flex-grid';
import GameCard from './GameCard';
import useResize from '../../hooks/useResize';
import ResGameList from './ResGameList';

const DisplayGames = ({ games }) => {
    const navigate = useNavigate();
    const { width } = useResize();
    return (
        <>
            {width > 576 ? (
                <Row
                    gutter={40}
                    style={{
                        marginTop: '1rem',
                    }}
                >
                    {games.map((item) => (
                        <Col key={item.gameId} xs={6} sm={4} md={4} lg={3} xl={3}>
                            <GameCard
                                key={item.gameId}
                                id={item.gameId}
                                type={item.itemType}
                                title={item.gameTitle}
                                imageURL={item.thumbnail}
                                onClick={() => navigate('/play/game/' + item.gameId)}
                            />
                            {item.timestamp && <div className="history-games-item-lg-time">{item.timestamp}</div>}
                        </Col>
                    ))}
                </Row>
            ) : (
                <div>
                    {games.map((item) => (
                        <ResGameList
                            key={item.gameId}
                            id={item.gameId}
                            type={item.itemType}
                            title={item.gameTitle}
                            imageURL={item.thumbnail}
                            onClick={() => navigate('/play/game/' + item.gameId)}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

export default DisplayGames;
