import { useSelector } from 'react-redux';
import { formatDistance } from 'date-fns';
import mergeByCommonKeys from '../utils/mergeByCommonKey';

const useGames = () => {
    const games = useSelector((state) => state.game.games);
    const favorites = useSelector((state) => state.game.gameFavorites);
    const history = useSelector((state) => state.game.gamesHistory);

    const getGamesCategory = () => {
        const categoryIds = [...new Set(games.map((game) => game.categoryId))];
        return categoryIds.map((categoryId) => ({
            categoryId: categoryId,
            categoryName: games.find((game) => game.categoryId === categoryId).categoryName,
        }));
    };

    const getCurrGame = (id) => {
        const gameId = parseInt(id);
        const currGame = games.filter((game) => game.gameId === gameId);
        return currGame[0];
    };

    const getRelatedVideos = (gameId) => {
        if (games.length === 0) return [];
        const id = parseInt(gameId);
        const categoryId = games.filter((x) => x.gameId === id)[0].categoryId;
        return games.filter((y) => y.categoryId === categoryId).filter((game) => game.gameId !== id);
    };
    const getCategoryAllGames = (categoryId) => {
        const id = parseInt(categoryId);
        return games.filter((game) => game.categoryId === id);
    };
    const getFavGames = () => {
        return games.filter((i) => favorites.indexOf(i.gameId) >= 0);
    };
    const getGameHistory = () => {
        const ids = history.map((i) => i.gameId);
        const historyGames = games.filter((game) => ids.indexOf(game.gameId) >= 0);
        return mergeByCommonKeys(historyGames, history, 'gameId').map((i) => ({
            ...i,
            timestamp: formatDistance(new Date(i.timestamp).getTime(), new Date().getTime(), { addSuffix: true }),
        }));
    };
    return { games, getGamesCategory, getCurrGame, getRelatedVideos, getCategoryAllGames, getFavGames, favorites, history, getGameHistory };
};
export default useGames;
