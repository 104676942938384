import React from 'react';
import { FaPlayCircle as PlayIcon, FaStar as StarIcon } from 'react-icons/fa';
import useTranslate from '../../hooks/useTranslate';
import MarkFavGame from './MarkFavGame';

const ResGameList = ({ id, imageURL, title, onClick }) => {
    const t = useTranslate();
    const style = {
        position: 'relative !important',
    };
    return (
        <div className="res-game-page">
            <div className="game-item-list">
                <img src={imageURL} alt={title} onClick={onClick} />
                <div className="game-title-section" onClick={onClick}>
                    <p>{title}</p>
                    <button>
                        <span>{t('watch_now')}</span>
                        <PlayIcon />
                    </button>
                </div>
                <MarkFavGame id={id} isList={true} />
            </div>
        </div>
    );
};

export default ResGameList;
