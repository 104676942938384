import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-simple-flex-grid';
import { FaStar as StarIcon } from 'react-icons/fa';
import Header from '../../components/Header/Header';
import VideoCard from '../../components/VideoCard/VideoCard';
import VideoList from '../../components/VideoList/VideoList';
import useTranslate from '../../hooks/useTranslate';
import useGetVideos from '../../hooks/useGetVideos';
import useResize from '../../hooks/useResize';
import useSearch from '../../hooks/useSearch';
import useGetSearchContents from '../../hooks/useGetSearchContents';
import ProtectedLink from '../../utils/ProtectedLink';
import classNames from 'classnames';
import useGetMovies from '../../hooks/useMovies';
import { _isAMBFooterSubdomain } from '../../constants/conditionals';
import useGames from '../../hooks/useGames';
import DisplayGames from '../../components/Games/DisplayGames';

export default function Favorites() {
    const [value, setValue] = React.useState('video');
    const t = useTranslate();
    const { searchTerm, ...searchProps } = useSearch();
    const { getFavoritesVideos } = useGetVideos();
    const { getFavoritesMovies } = useGetMovies();
    const { getFavGames } = useGames();
    const favoritesGames = getFavGames();
    const videoFavorites = value === 'movie' ? getFavoritesMovies() : getFavoritesVideos();
    const searchVideos = useGetSearchContents(searchTerm, videoFavorites);
    const searchGames = useGetSearchContents(searchTerm, favoritesGames, '', 'games');
    return (
        <>
            <Header headerText={t('favorites')} searchTerm={searchTerm} {...searchProps} />
            <div className={classNames('favorites-page', { 'page-layout-amb-footer': _isAMBFooterSubdomain })}>
                <RadioButtonsToggler name="type" values={['movie', 'video', 'games']} onChange={(v) => setValue(v)} currentValue={value} />
                <div className="favorites-videos">
                    {/* {searchTerm && searchTerm.length > 0 ? (
                        <DisplayVideos videos={searchVideos} />
                    ) : (value === 'video' || value === 'movie') && videoFavorites && videoFavorites.length > 0 ? (
                        <DisplayVideos videos={videoFavorites} />
                    ) : favoritesGames && favoritesGames.length > 0 ? (
                        <DisplayGames games={favoritesGames} />
                    ) : (
                        <div className="no-favorites-videos">
                            <StarIcon />
                            <div className="title">{t('no_favorite_videos')}</div>
                            <div className="sub">{t('add_new_fav_videos')}</div>
                            <ProtectedLink to="/home">{t('explore_videos')}</ProtectedLink>
                        </div>
                    )} */}
                    {searchTerm && searchTerm.length > 0 ? (
                        value !== 'games' ? (
                            <DisplayVideos videos={searchVideos} />
                        ) : (
                            <DisplayGames videos={searchGames} />
                        )
                    ) : value !== 'games' && videoFavorites && videoFavorites.length > 0 ? (
                        <DisplayVideos videos={videoFavorites} />
                    ) : favoritesGames && favoritesGames.length > 0 ? (
                        <DisplayGames games={favoritesGames} />
                    ) : (
                        <div className="no-favorites-videos">
                            <StarIcon />
                            <div className="title">{t(`${value === 'games' ? 'no_favorite_game' : 'no_favorite_videos'}`)}</div>
                            <div className="sub">{t(`${value === 'games' ? 'add_new_fav_games' : 'add_new_fav_videos'}`)}</div>
                            <ProtectedLink to="/home">{t(`${value === 'games' ? 'explore_games' : 'explore_videos'}`)}</ProtectedLink>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

const DisplayVideos = ({ videos }) => {
    const { width } = useResize();
    const navigate = useNavigate();
    return (
        <>
            {width > 576 ? (
                <Row
                    gutter={40}
                    style={{
                        marginTop: '1rem',
                    }}
                >
                    {videos.map((item) => (
                        <Col key={item.videoId} xs={6} sm={4} md={4} lg={3} xl={3}>
                            <VideoCard
                                id={item.videoId}
                                type={item.itemType}
                                title={item.videoTitle}
                                imageURL={item.videoPreview}
                                onClick={() => navigate(`${item.itemType === 'movies' ? '/play/movie/' : '/play/video/'}` + item.videoId)}
                                className="favorites-video-card"
                            />
                        </Col>
                    ))}
                </Row>
            ) : (
                <div className="favorites-videos-list">
                    {videos.map((item) => (
                        <VideoList
                            key={item.videoId}
                            id={item.videoId}
                            type={item.itemType}
                            imageURL={item.videoPreview}
                            title={item.videoTitle}
                            addlText={item.timestamp}
                            onClick={() => navigate(`${item.itemType === 'movies' ? '/play/movie/' : '/play/video/'}` + item.videoId)}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

const RadioButtonsToggler = ({ name, values, onChange, currentValue }) => {
    const t = useTranslate();
    return (
        <div className="radio-button-toggler">
            <>
                {values.map((x) => (
                    <div key={x}>
                        <input type="radio" value={x} checked={x === currentValue} name={name} id={x} onChange={(e) => onChange(e.target.value)} />
                        <label htmlFor={x}>{t(x)}</label>
                    </div>
                ))}
            </>
        </div>
    );
};
