import makeApiRequest from '../utils/makeApiRequest';

export const actionTypes = {
    SET_COUNTRY: 'SET_COUNTRY',
    SET_LANGUAGE: 'SET_LANGUAGE',
    SHOW_LANG_MODAL: 'SHOW_LANG_MODAL',
    SET_USER_DETAILS: 'SET_USER_DETAILS',
    SET_ACCESS_DETAILS: 'SET_ACCESS_DETAILS',
    SET_ERROR_DETAILS: 'SET_ERROR_DETAILS',
    SET_VIDEOS: 'SET_VIDEOS',
    SET_VIDEOS_HISTORY: 'SET_VIDEOS_HISTORY',
    SET_VIDEOS_FAVORITE: 'SET_VIDEOS_FAVORITE',
    ADD_TO_VIDEO_HISTORY: 'ADD_TO_VIDEO_HISTORY',
    CLEAR_VIDEO_HISTORY: 'CLEAR_VIDEO_HISTORY',
    ADD_FAVOURITE_VIDEO: 'ADD_FAVORITE_VIDEO',
    REMOVE_FAVOURITE_VIDEO: 'REMOVE_FAVORITE_VIDEO',
    ADD_TO_LIKES: 'ADD_TO_LIKES',
    REMOVE_FROM_LIKES: 'REMOVE_FROM_LIKES',
    UPDATE_USER_DETAILS: 'UPDATE_USER_DETAILS',
    SET_MOVIES: 'SET_MOVIES',
    SET_MOVIE_FAVOURITE: 'SET_MOVIE_FAVOURITE',
    ADD_FAVOURITE_MOVIE: 'ADD_FAVORITE_MOVIE',
    REMOVE_FAVOURITE_MOVIE: 'REMOVE_FAVORITE_MOVIE',
    SET_MOVIE_HISTORY: 'SET_MOVIE_HISTORY',
    ADD_TO_MOVIE_HISTORY: 'ADD_TO_MOVIE_HISTORY',
    CLEAR_MOVIE_HISTORY: 'CLEAR_MOVIE_HISTORY',
    SET_GAMES: 'SET_GAME',
    SET_FAVOURITE_GAMES: 'SET_FAVOURITE_GAMES',
    SET_GAMES_HISTORY: 'SET_GAMES_HISTORY',
    Add_TO_FAVOURITE_GAME: 'ADD_TO_FAVOURITE_GAME',
    ADD_TO_GAME_HISTORY: 'ADD_TO_GAME_HISTORY',
    REMOVE_FAVOURITE_GAME: 'REMOVE_FAVOURITE_GAME',
    CLEAR_GAME_HISTORY: 'CLEAR_GAME_HISTORY',
};

export const createAction = (type, payload) => ({
    type,
    payload,
});

export const setCountry = (payload) => createAction(actionTypes.SET_COUNTRY, payload);
export const setLang = (payload) => createAction(actionTypes.SET_LANGUAGE, payload);
export const showLangModal = (payload) => createAction(actionTypes.SHOW_LANG_MODAL, payload);
export const setUserDetails = (payload) => createAction(actionTypes.SET_USER_DETAILS, payload);
export const setAccessDetails = (payload) => createAction(actionTypes.SET_ACCESS_DETAILS, payload);
export const setErrorDetails = (payload) => createAction(actionTypes.SET_ERROR_DETAILS, payload);
export const setVideos = (payload) => createAction(actionTypes.SET_VIDEOS, payload);
export const setVideoHistory = (payload) => createAction(actionTypes.SET_VIDEOS_HISTORY, payload);
export const setVideoFavourite = (payload) => createAction(actionTypes.SET_VIDEOS_FAVORITE, payload);
export const addToLikes = (id) => createAction(actionTypes.ADD_TO_LIKES, id);
export const removeFromLikes = (id) => createAction(actionTypes.REMOVE_FROM_LIKES, id);
export const setMovies = (payload) => createAction(actionTypes.SET_MOVIES, payload);
export const setMovieFavourite = (payload) => createAction(actionTypes.SET_MOVIE_FAVOURITE, payload);
export const setMovieHistory = (payload) => createAction(actionTypes.SET_MOVIE_HISTORY, payload);
export const setGames = (payload) => createAction(actionTypes.SET_GAMES, payload);
export const setFavouriteGames = (payload) => createAction(actionTypes.SET_FAVOURITE_GAMES, payload);
export const setHistoryGames = (payload) => createAction(actionTypes.SET_GAMES_HISTORY, payload);

export const addFavourite = (videoId, itemType) => {
    return async (dispatch, getState) => {
        const authorization = sessionStorage.getItem('authorization');
        const token = getState().user.token || sessionStorage.getItem('token');
        if (!token) return;
        dispatch({
            type:
                itemType === 'movies'
                    ? actionTypes.ADD_FAVOURITE_MOVIE
                    : itemType === 'games'
                    ? actionTypes.SET_FAVOURITE_GAMES
                    : actionTypes.ADD_FAVOURITE_VIDEO,
            payload: videoId,
        });
        await makeApiRequest({
            url: new URL(window.location.origin + '/api/videos/fav/add').href,
            method: 'POST',
            body: { token, videoId },
            headers: { authorization },
        });
    };
};
export const removeFavourite = (videoId, itemType) => {
    return async (dispatch, getState) => {
        const authorization = sessionStorage.getItem('authorization');
        const token = getState().user.token || sessionStorage.getItem('token');
        if (!token) return;
        dispatch({
            type: itemType === 'movies' ? actionTypes.REMOVE_FAVOURITE_MOVIE : actionTypes.REMOVE_FAVOURITE_VIDEO,
            payload: videoId,
        });
        await makeApiRequest({
            url: new URL(window.location.origin + '/api/videos/fav/clear').href,
            method: 'DELETE',
            body: { token, videoId },
            headers: { authorization },
        });
    };
};

export const addToHistory = (videoId, itemType) => {
    return async (dispatch, getState) => {
        const authorization = sessionStorage.getItem('authorization');
        const token = getState().user.token || sessionStorage.getItem('token');
        if (!token) return;
        const resp = await makeApiRequest({
            url: new URL(window.location.origin + '/api/videos/history/add').href,
            method: 'POST',
            body: { token, videoId },
            headers: { authorization },
        });
        if (!resp.success) return;
        dispatch({
            type: itemType === 'movies' ? actionTypes.ADD_TO_MOVIE_HISTORY : actionTypes.ADD_TO_VIDEO_HISTORY,
            payload: {
                videoId,
                timestamp: new Date().toISOString(),
            },
        });
    };
};

export const clearHistory = (itemType) => {
    return async (dispatch, getState) => {
        const authorization = sessionStorage.getItem('authorization');
        const token = getState().user.token || sessionStorage.getItem('token');
        if (!token) return;
        const resp = await makeApiRequest({
            url: new URL(window.location.origin + `/api/${itemType === 'games' ? 'games' : 'videos'}/history/clear`).href,
            method: 'DELETE',
            body: { token },
            headers: { authorization },
        });
        if (!resp.success) return;
        if (itemType !== 'games') {
            dispatch({
                type: actionTypes.CLEAR_MOVIE_HISTORY,
            });
            dispatch({
                type: actionTypes.CLEAR_VIDEO_HISTORY,
            });
        } else {
            dispatch({
                type: actionTypes.CLEAR_MOVIE_HISTORY,
            });
        }
    };
};

export const updateUserDetails = (body) => {
    return async (dispatch, getState) => {
        const authorization = sessionStorage.getItem('authorization');
        const token = getState().user.token || sessionStorage.getItem('token');
        if (!token) return;
        const resp = await makeApiRequest({
            url: new URL(window.location.origin + '/api/user/update').href,
            method: 'POST',
            body: { token, ...body },
            headers: { authorization },
        });
        if (!resp.success) return;
        dispatch({
            type: actionTypes.UPDATE_USER_DETAILS,
            payload: body,
        });
    };
};
export const addToFavouriteGame = (gameId) => {
    return async (dispatch, getState) => {
        const authorization = sessionStorage.getItem('authorization');
        const token = getState().user.token || sessionStorage.getItem('token');
        if (!token) return;
        const resp = await makeApiRequest({
            url: new URL(window.location.origin + '/api/games/fav/add').href,
            method: 'POST',
            body: { token, gameId },
            headers: { authorization },
        });
        console.log('resp', resp);
        if (!resp.success) return;
        dispatch({ type: actionTypes.Add_TO_FAVOURITE_GAME, payload: gameId });
    };
};
export const removeFavouriteGame = (gameId) => {
    return async (dispatch, getState) => {
        const authorization = sessionStorage.getItem('authorization');
        const token = getState().user.token || sessionStorage.getItem('token');
        if (!token) return;
        await makeApiRequest({
            url: new URL(window.location.origin + '/api/games/fav/clear').href,
            method: 'DELETE',
            body: { token, gameId },
            headers: { authorization },
        });

        dispatch({ type: actionTypes.REMOVE_FAVOURITE_GAME, payload: gameId });
    };
};
export const addToGameHistory = (gameId) => {
    return async (dispatch, getState) => {
        const authorization = sessionStorage.getItem('authorization');
        const token = getState().user.token || sessionStorage.getItem('token');
        if (!token) return;
        const resp = await makeApiRequest({
            url: new URL(window.location.origin + '/api/games/history/add').href,
            method: 'POST',
            body: { token, gameId },
            headers: { authorization },
        });
        if (!resp.success) return;
        dispatch({
            type: actionTypes.ADD_TO_GAME_HISTORY,
            payload: {
                gameId,
                timestamp: new Date().toISOString(),
            },
        });
    };
};
